.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--amplify-colors-background-secondary);
}

.page-container {
  flex: 1;
  padding: 30px;
  padding-left: 290px;
  padding-top: 50px;
  transition: padding 0.5s;
  overflow-x: hidden;
}

@media (max-width: 1024px) {
  .page-container {
    padding: 30px;
    padding-left: 20px;
    padding-top: 50px;
    transition: padding 0.5s;
  }
}

.card-title {
  font-weight: 600;
}